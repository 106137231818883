import React, { useState } from 'react';
import { Card, Checkbox, Button } from 'antd';

const NoteFailAkses = () => {
  // State to track whether the user has agreed
  const [isAgreed, setIsAgreed] = useState(false);

  // Handle checkbox change
  const handleCheckboxChange = (event) => {
    setIsAgreed(event.target.checked);
  };

  return (
    <Card
      title="Pernyataan Persetujuan (Pelanggan)"
      style={{
        maxWidth: 600,
        margin: '20px auto',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
      <p>
        Mohon diperhatikan bahwa dengan menghapus akun Gojek dan GoPay Anda:
      </p>
      <ul>
        <li>
          Anda tidak akan lagi dapat mengakses fitur dan layanan yang ditawarkan melalui platform Gojek dan GoPay. Oleh karena itu, Anda mungkin ingin menyimpan catatan riwayat transaksi Anda dalam aplikasi Gojek dan/atau GoPay sebelum melanjutkan proses penghapusan akun.
        </li>
        <li>
          Anda akan kehilangan semua voucher atau saldo yang belum digunakan dalam akun Gojek dan GoPay Anda, yang tidak dapat dipulihkan, ditransfer ke akun Gojek dan GoPay lain, dan diuangkan.
        </li>
        <li>
          Jika Anda ingin menggunakan aplikasi Gojek dan GoPay lagi setelah menghapus akun Anda, Anda akan diminta untuk mendaftar akun baru.
        </li>
        <li>
          Kami berhak menyimpan beberapa informasi dan/atau data pribadi yang terkait dengan akun Gojek dan GoPay Anda, sepanjang diperlukan oleh hukum yang berlaku.
        </li>
      </ul>
      <p>
        Dengan mengirimkan permintaan ini, Anda mengkonfirmasi bahwa:
      </p>
      <ul>
        <li>Anda adalah pemilik akun yang bersangkutan,</li>
        <li>Anda telah membaca dan memahami konsekuensi penghapusan akun Gojek dan GoPay sebagaimana telah dijelaskan di atas, dan</li>
        <li>Anda setuju untuk memberikan izin kepada kami untuk menghapus akun Gojek dan GoPay Anda.</li>
      </ul>
      <p>
        Kami berhak untuk menolak permintaan penghapusan akun Anda jika diizinkan atau diperlukan berdasarkan hukum yang berlaku. Ini termasuk situasi di mana kami menganggap permintaan tersebut tidak relevan, tidak serius atau mengada-ada, atau jika terkait dengan pelanggaran ketentuan penggunaan atau kegiatan yang melawan hukum.
      </p>
      <p>
        Email konfirmasi akan dikirimkan setelah akun Gojek dan GoPay Anda berhasil dihapus.
      </p>

      {/* Checkbox for consent */}
      <div style={{ marginBottom: '10px' }}>
        <Checkbox
          checked={isAgreed}
          onChange={handleCheckboxChange}
        >
          Setuju? Silakan setujui untuk melanjutkan.
        </Checkbox>
      </div>

      {/* Conditionally render text */}
      {!isAgreed && (
        <p style={{ color: 'red' }}>
          Silakan setujui untuk melanjutkan.
        </p>
      )}
    </Card>
  );
};

export default NoteFailAkses;
