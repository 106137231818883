import { Card } from 'antd';
import React from 'react';

const NoteAkses = () => {
  return (
    <Card
        style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "20px",
            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",
            padding: "20px",
            margin: "0 auto",
            maxWidth: "100%",
            marginBottom: "15px"
          }}
    >
      <h4>Penghapusan akun melalui aplikasi Gojek atau GoPay</h4>
      <p><strong>Untuk menghapus akun melalui aplikasi Gojek, ikuti cara berikut:</strong></p>
      <ul>
        <li>Pada beranda aplikasi, ketuk ikon Profil Saya di pojok kanan atas</li>
        <li>Pilih menu Pengaturan akun</li>
        <li>Pilih Hapus akun, centang persetujuan, lalu ketuk Lanjut</li>
      </ul>
      <p><strong>Untuk menghapus akun melalui aplikasi GoPay, ikuti cara berikut:</strong></p>
      <ul>
        <li>Pada beranda aplikasi, ketuk ikon Profil Saya di pojok kiri atas</li>
        <li>Pilih menu Pengaturan akun & aplikasi</li>
        <li>Pilih Hapus atau nonaktifkan akun</li>
      </ul>
    </Card>
  );
};

export default NoteAkses;
