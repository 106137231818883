import React, { useState } from 'react';
import { Card, Checkbox } from 'antd';

const NoteMitraUsaha = () => {
  const [isAgreed, setIsAgreed] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsAgreed(e.target.checked);
  };

  return (
    <Card
      title="Pernyataan Persetujuan (Mitra Usaha)"
      style={{
        maxWidth: 600,
        margin: '20px auto',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
      <p>
        Mohon diperhatikan bahwa dengan menghapus akun GoBiz dan GoPay Merchant Anda:
      </p>
      <ul>
        <li>
          Anda tidak akan lagi dapat mengakses fitur dan layanan yang ditawarkan melalui platform GoBiz dan GoPay Merchant. Oleh karena itu, Anda mungkin ingin menyimpan catatan riwayat transaksi Anda dalam aplikasi GoBiz dan GoPay Merchant sebelum melanjutkan proses penghapusan akun.
        </li>
        <li>
          Anda akan kehilangan semua voucher atau saldo yang belum digunakan dalam akun GoBiz dan GoPay Merchant Anda, yang tidak dapat dipulihkan, ditransfer ke akun GoBiz dan GoPay Merchant lain, dan diuangkan.
        </li>
        <li>
          Jika Anda ingin menggunakan aplikasi GoBiz dan GoPay Merchant lagi setelah menghapus akun Anda, Anda akan diminta untuk mendaftar akun baru.
        </li>
        <li>
          Kami berhak menyimpan beberapa informasi dan/atau data pribadi yang terkait dengan akun GoBiz dan GoPay Merchant Anda, sepanjang diperlukan oleh hukum yang berlaku.
        </li>
      </ul>
      <p>
        Dengan mengirimkan permintaan ini, Anda mengkonfirmasi bahwa:
      </p>
      <ul>
        <li>Anda adalah pemilik akun yang bersangkutan,</li>
        <li>
          Anda telah membaca dan memahami konsekuensi penghapusan akun GoBiz dan GoPay Merchant sebagaimana telah dijelaskan di atas, dan
        </li>
        <li>
          Anda setuju untuk memberikan izin kepada kami untuk menghapus akun GoBiz dan GoPay Merchant Anda.
        </li>
      </ul>
      <p>
        Kami berhak untuk menolak permintaan penghapusan akun Anda jika diizinkan atau diperlukan berdasarkan hukum yang berlaku. Ini termasuk situasi di mana kami menganggap permintaan tersebut tidak relevan, tidak serius atau mengada-ada, atau jika terkait dengan pelanggaran ketentuan penggunaan atau kegiatan yang melawan hukum.
      </p>
      <p>
        Email konfirmasi akan dikirimkan setelah akun GoBiz dan GoPay Merchant Anda berhasil dihapus.
      </p>

      {/* Checkbox for consent */}
      <div style={{ marginBottom: '10px' }}>
        <Checkbox
          checked={isAgreed}
          onChange={handleCheckboxChange}
        >
          Setuju? Silakan setujui untuk melanjutkan.
        </Checkbox>
      </div>

      {/* Conditionally render text */}
      {!isAgreed && (
        <p style={{ color: 'red' }}>
          Silakan setujui untuk melanjutkan.
        </p>
      )}
    </Card>
  );
};

export default NoteMitraUsaha;
