import axios from "axios";
import { useEffect, useState } from "react";
import { Form, Input, Button, Select, Card, Row, Col } from "antd";
import { Link } from "react-router-dom";
import DesapediaLogo from "../../../assets/images/Desapedia-01.png";
import { config } from "../../../config";
import NoteAkses from "../Note/NoteAkses";
import NoteFailAkses from "../Note/NoteFailAkses";

const { Option } = Select;

const PrivacyWebForm = () => {
  const [userType, setUserType] = useState(null);
  const [requestType, setRequestType] = useState(null);
  const [requestSubType, setRequestSubType] = useState(null);
  const [deleteAccount, setDeleteAccunt] = useState(null);
  const [deleteReason, setDeleteReason] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(null);
  const [formOptions, setFormOptions] = useState({
    userTypeOptions: [],
    requestTypeOptions: [],
    requestSubTypeOptions: [],
    deleteRequestOptions: [],
    deleteReasonOptions: [],
    deleteConfirmOptions: [],
  });

  const onChangeTypePengguna = (value) => {
    setUserType(value);
    const newData = formOptions.deleteRequestOptions.filter(
      (data) => data.mtipePenggunaId === value
    );
    setFormOptions({
      ...formOptions,
      deleteRequestOptions: newData,
    });
  };

  const onChangeTypePermintaan = (value) => {
    setRequestType(value);
    const newData = formOptions.requestTypeOptions.filter(
      (data) => data.mtipePengajuanId === value
    );
    setFormOptions({
      ...formOptions,requestTypeOptions: newData,
    });
  };
  
  const onChangeTypeSubPermintaan = (value) => {
    setRequestSubType(value);
    const newData = formOptions.requestSubTypeOptions.filter(
      (data) => data.mjenisSubPengajuanId === value
    );
    setFormOptions({
      ...formOptions,requestSubTypeOptions: newData,
    });
  };
  
  const onChangeDeleteAccount = (value) => {
    // console.log('Account Delete:', value);
    setDeleteAccunt(value);

    const newData = formOptions.deleteRequestOptions.filter(
      (data) => data.mtipePenggunaId === value
    );
    // console.log('New Data: ', newData);
    
    setFormOptions({
      ...formOptions,deleteRequestOptions: newData,
    });
    // console.log('FormOptions Delete : ', formOptions.deleteRequestOptions);
  };
  
  const onChangeDeleteReason = (value) => {
    console.log("Reason Delete:", value);
    setDeleteReason(value);
  
    // Filter options where mjenisAkunId matches the selected value
    const newData = formOptions.deleteReasonOptions.filter(
      (data) => data.mjenisAkunId === value || data.mtipePengajuanId === value || data.mreasonPengajuanId === value
    );
  
    console.log("New Data: ", newData);
  
    // Update formOptions with filtered data
    setFormOptions((prevOptions) => ({
      ...prevOptions,
      deleteReasonOptions: newData,
    }));
  
    console.log("Updated FormOptions Reason: ", formOptions.deleteReasonOptions);
  };
  

  const onChangeDeleteAccess = (value) => {
    console.log("Delete Access:", value);
    setDeleteConfirm(value);
  
    // Filter options where mjenisAkunId matches the selected value
    const newData = formOptions.deleteConfirmOptions.filter(
      (data) => data.mreasonAksesAkunId === value
    );
  
    console.log("New Data: ", newData);
  
    // Update formOptions with filtered data
    setFormOptions((prevOptions) => ({
      ...prevOptions,
      deleteConfirmOptions: newData,
    }));
  
    console.log("Updated FormOptions Delete Access: ", formOptions.deleteConfirmOptions);

  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${config.BASE_URL_AJU}pengajuanAkun/referensi`
        );
        const data = response.data.data;

        setFormOptions({
          userTypeOptions: data.tipePengguna,
          requestTypeOptions: data.tipePengajuan,
          requestSubTypeOptions: data.jenisSubPengajuan,
          deleteRequestOptions: data.jenisAkun,
          deleteReasonOptions: data.reasonPengajuan,
          deleteConfirmOptions: data.reasonAksesAkun,
        });
      } catch (error) {
        console.error("Error fetching data from API:", error);
      }
    };

    fetchData();
  }, []);

  const handleFormSubmit = async (values) => {
    try {
      const payload = {
        createdBy: values.accountName,
        email: values.email,
        isactive: "true",
        isupgrade: "false",
        keterangan: values.requestDetails,
        mjenisAkunId: deleteAccount,
        mjenisSubPengajuanId: requestSubType,
        mreasonAksesAkunId: deleteConfirm,
        mreasonPengajuanId: deleteReason,
        mtipePengajuanId: requestType,
        mtipePenggunaId: userType,
        nama: values.accountName,
        nomorHp: values.nomor,
        pengajuanAkunId: "12345",
        updatedBy: values.accountName,
      };

      const response = await axios.post(
        `${config.BASE_URL_AJU}pengajuanAkun/create`,
        payload
      );
      console.log("Form submitted successfully:", response.data);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  // Check if the selected deleteConfirm matches the specific ID
  const isNoteAksesVisible = deleteConfirm === "8ba86400-31aa-49d7-9df8-7f2be51dbb33";
  const isNoteFailAksesVisible = deleteConfirm === "de2a999a-77b3-4d66-9210-2188e3fb151e";


  return (
    <>
      <header className="privacy-header">
        <Row>
          <Col span={4}>
            <Link to="/">
              <img src={DesapediaLogo} alt="Desapedia Logo" height="35" />
            </Link>
          </Col>
        </Row>
      </header>

      <section className="privacy-intro">
        <Row className="justify-content-center">
          <div className="col-lg-7">
            <div className="text-center mb-5">
              <h1>Formulir privasi</h1>
              <p>
                Desapedia memahami bahwa pengalaman pelanggan yang lebih baik
                dimulai dengan privasi dan perlindungan data. Kami senang dapat
                membantu setiap pertanyaan, masukan, atau permintaan yang Anda
                miliki mengenai Pemberitahuan Privasi Desapedia, praktik privasi
                dan perlindungan data kami, atau bagaimana Desapedia menerapkan
                hukum privasi dan perlindungan data.
              </p>
            </div>
          </div>
        </Row>
      </section>

      <section className="privacy-form">
        <Row justify="center">
          <Col lg={15}>
            <Card
              style={{
                backgroundColor: "#F5F5F5",
                borderRadius: "20px",
                boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",
                padding: "20px",
                margin: "0 auto",
                maxWidth: "100%",
              }}
            >
              <Form layout="vertical" onFinish={handleFormSubmit}>
                <Form.Item
                  label="Pilih tipe pengguna"
                  name="userType"
                  required
                >
                  <Select
                    placeholder="Pilih tipe pengguna"
                    onChange={onChangeTypePengguna}
                    value={userType}
                    allowClear
                    className="rounded-input"
                  >
                    {formOptions.userTypeOptions.map((option) => (
                      <Option key={`userType-${option.mtipePenggunaId}`} value={option.mtipePenggunaId}>
                        {option.nama}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                {userType && (
                  <Form.Item
                    label="Pilih tipe pengajuan"
                    name="requestType"
                    required
                  >
                    <Select
                      placeholder="Pilih tipe pengajuan"
                      onChange={onChangeTypePermintaan}
                      value={requestType}
                      allowClear
                      className="rounded-input"
                    >
                      {formOptions.requestTypeOptions.map((option) => (
                        <Option key={`requestType-${option.mtipePengajuanId}`} value={option.mtipePengajuanId}>
                          {option.nama}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}

                {requestType && (
                  <Form.Item
                    label="Pilih tipe sub permintaan"
                    name="requestSubType"
                    required
                  >
                    <Select
                      placeholder="Pilih tipe sub permintaan"
                      onChange={onChangeTypeSubPermintaan}
                      value={requestSubType}
                      allowClear
                      className="rounded-input"
                    >
                      {formOptions.requestSubTypeOptions.map((option) => (
                        <Option key={`requestSubType-${option.mjenisSubPengajuanId}`} value={option.mjenisSubPengajuanId}>
                          {option.nama}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}

                {requestSubType && (
                  <Form.Item
                    label="Pilih akun yang ingin dihapus"
                    name="deleteAccount"
                    required
                  >
                    <Select
                      placeholder="Pilih akun yang ingin dihapus"
                      onChange={onChangeDeleteAccount}
                      value={deleteAccount}
                      allowClear
                      className="rounded-input"
                    >
                      {formOptions.deleteRequestOptions.map((option) => (
                        <Option key={`deleteAccount-${option.mtipePenggunaId}`} value={option.mtipePenggunaId}>
                          {option.nama}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}

                {deleteAccount && (
                  <Form.Item
                    label="Pilih alasan penghapusan akun"
                    name="deleteReason"
                    required
                  >
                    <Select
                      placeholder="Pilih alasan penghapusan akun"
                      onChange={onChangeDeleteReason}
                      value={deleteReason}
                      allowClear
                      className="rounded-input"
                    >
                      {formOptions.deleteReasonOptions.map((option) => (
                        <Option
                          key={`deleteReason-${option.mreasonPengajuanId}`}
                          value={option.mreasonPengajuanId}
                        >
                          {option.isi}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}

                {deleteReason && (
                  <Form.Item
                    label="Apakah Anda masih memiliki akses ke akun yang ingin Anda hapus?"
                    name="deleteConfirm"
                    required
                  >
                    <Select
                      placeholder="Apakah Anda masih memiliki akses ke akun?"
                      onChange={onChangeDeleteAccess}
                      value={deleteConfirm}
                      allowClear
                      className="rounded-input"
                    >
                      {formOptions.deleteConfirmOptions.map((option) => (
                        <Option
                          key={`deleteConfirm-${option.mreasonAksesAkunId}`}
                          value={option.mreasonAksesAkunId}
                        >
                          {option.isi}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}

                {isNoteAksesVisible && <NoteAkses />}
                {isNoteFailAksesVisible && <NoteFailAkses />}
                
                {/* Hide the entire block of fields when deleteConfirm is a specific value */}
                {!isNoteAksesVisible && (
                  <>
                    <Form.Item
                      label="Detail Permintaan"
                      name="requestDetails"
                      required
                    >
                      <Input.TextArea rows={4} placeholder="Detail Permintaan" />
                    </Form.Item>
                
                    <Form.Item label="Nama Pengguna" name="accountName" required>
                      <Input.TextArea
                        placeholder="Nama Pengguna"
                        style={{ borderRadius: "20px" }}
                      />
                    </Form.Item>
                
                    <Form.Item label="Nomor yang terdaftar" name="nomor" required>
                      <Input.TextArea
                        placeholder="Nomor yang terdaftar"
                        style={{ borderRadius: "20px" }}
                      />
                    </Form.Item>
                
                    <Form.Item label="Email terdaftar" name="email" required>
                      <Input.TextArea
                        placeholder="Email terdaftar"
                        style={{ borderRadius: "20px" }}
                      />
                    </Form.Item>
                
                    <Form.Item>
                      <Button
                        type="primary"
                        shape="round"
                        htmlType="submit"
                        style={{
                          backgroundColor: "#F2643E",
                          borderColor: "#F2643E",
                          padding: "10px 30px",
                          fontSize: "15px",
                        }}
                      >
                        Kirim
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form>
            </Card>
          </Col>
        </Row>
      </section>
              
      <footer className="privacy-footer">
        <Row justify="center">
          <Col span={24} className="text-center">
            <p>
              &copy; 2025 Desapedia | Desapedia adalah merek dagang dari PT
              Agriva Teknologi Nusantara. Terdaftar di Direktorat Jenderal
              Kekayaan Intelektual Republik Indonesia.
            </p>
          </Col>
        </Row>
      </footer>
    </>
  );
};

export default PrivacyWebForm;
